<template>
	<div class="p-grid">
		<div class="p-col-12 p-md-6">
			<div class="">
				<h5>Informations personnelles</h5>
				<p class="text-muted">Ces informations sont utilisées pour communiquer avec vous.</p>

				<div v-if="userData !== undefined" class="card p-fluid">

					<div class="p-field">
						<label for="name1">Nom</label>
						<InputText v-model="userData.lastname" id="name1" type="text" />
					</div>
					<div class="p-field">
						<label for="surname">Prénom</label>
						<InputText v-model="userData.firstname" id="surname" type="text" />
					</div>
					<div class="p-field">
						<label for="poste">Poste</label>
						<InputText v-model="userData.poste" id="poste" type="text" />
					</div>
					<div class="p-field">
						<label for="email">Email</label> <small class="text-muted">Changer d'email requiert une validation</small>
						<InputText :value="userData.username" @input="updateEmail" id="email" type="text" />
					</div>

					<div class="p-field p-d-flex p-jc-left p-ai-center">
						<InputSwitch id="notifcontact" v-model="userData.email_notifications" />
						<div class="p-as-center p-ml-2 p-switch-label">Notifications journalières<br>
							<small class="text-muted">Activer les notifications journalières de contact</small></div>
					</div>

					<div class="p-field">
						<label for="ran_">Mot de passe</label><br>
						<InputText v-model="newPassword"
								   v-bind:class="{'p-mt-1': true, 'p-invalid': !passwordValid}"
								   autocomplete="off" id="ran_" type="password" />
						<small class="text-muted">6 à 20 caractères contenant au moins un chiffre, une lettre majuscule et une lettre minuscule.</small>
					</div>

					<div v-show="newPassword !== ''" class="p-field">
						<label for="ran2_">Validation du mot de passe</label>
						<InputText v-model="newPasswordValidation" autocomplete="off" id="ran2_" type="password" />
					</div>

					<div class="p-field">
						<Button label="Mettre à jour"
								:disabled="newPassword !== '' && newPassword !== newPasswordValidation && !passwordValid"
								@click="changeProfilData"
								icon="pi pi-save" class="p-mt-2 p-mb-2 color: b-button"></Button>
					</div>
				</div>
			</div>
		</div>

		<div class="p-col-12 p-md-6">
			<div v-if="userData !== undefined && userHasRole('ROLE_CALENDAR')" class="">
				<h5>Agenda & rendez-vous</h5>
				<p class="text-muted">Choisissez l'agenda sur lequel seront sauvergardés les rendez-vous.</p>

				<div v-if="userData !== undefined && userData.has_google === true" class="card p-fluid">
					<h5>Paramètres de l'agenda</h5>

					<div v-if="userData.haveBusinessHours" class="p-fluid">
						<div v-if="userHasRole('ROLE_SUPERVISOR')" class="p-field">
							<div class="p-field p-d-flex p-jc-left p-ai-center">
								<InputSwitch id="subuserbusinesshours" v-model="userData.subUserHaveBusinessHours" />
								<div class="p-as-center p-ml-2 p-switch-label">Chaque sous-utilisateur peut définir ses paramètres<br>
									<small class="text-muted">En dé-cochant cette option les paramètres définis sur cette page
										le sont pour tous les utilisateurs de l'organisation.</small></div>
							</div>
						</div>

						<div class="p-field">
							<label>Les clients peuvent réserver en ligne au plus tard :</label>
							<TimeHoursSelector :value="userData.minimumHourBeforeMeeting"
											   @input="userData.minimumHourBeforeMeeting = $event"></TimeHoursSelector>
							<small class="text-muted">à l'avance</small>
						</div>

						<div class="p-field">
							<label>Les prestations durent :</label>
							<TimeHoursSelector :value="userData.prestationDurationMinute"
											   :minutes-mode="true"
											   @input="userData.prestationDurationMinute = $event"></TimeHoursSelector>
						</div>

						<div class="p-field">
							<label>Les clients peuvent réserver en ligne chaque : </label>
							<TimeHoursSelector :value="userData.timeslotSizeMinute"
											   :minutes-mode="true"
											   @input="userData.timeslotSizeMinute = $event"></TimeHoursSelector>
						</div>

						<!--<div v-if="false" class="p-field">
							<label>Envoyer un email de rappel au client : </label>
							<TimeHoursSelector :value="userData.notifyClientBeforeHours"
											   :add-never="true"
											   @input="userData.notifyClientBeforeHours = $event"></TimeHoursSelector>
							<small class="text-muted"> avant le rendez-vous</small>
						</div>-->

						<div class="p-field p-d-flex p-jc-left p-ai-center">
							<InputSwitch id="subuserbusinesshours" v-model="userData.autoValidateMeetings" />
							<div class="p-as-center p-ml-2 p-switch-label">Accepter automatiquement les rendez-vous<br>
								<small class="text-muted">Si cette option est activée, vous n'aurez pas à valider les rendez-vous pris
									par les clients via l'application. Ceux-ci seront automatiquement ajoutés à votre Google Agenda.</small></div>
						</div>


						<Button label="Mettre à jour"
								@click="changeCalendarSettings"
								icon="pi pi-save" class="p-mt-2 p-mb-2 b-button"></Button>
					</div>
					<div v-else class="p-fluid p-text-center">
						<small class="text-muted">Les options de l'agenda sont gérés par votre administrateur !</small>
					</div>
				</div>

				<div v-if="userData === undefined || userData.has_google !== true" class="card p-fluid">
					<h5>Lier votre compte Google</h5>
					<p class="text-muted">Lier votre compte Google pour accéder aux fonctionnalités du calendrier.</p>
					<Button label="Connecter compte Google"
							@click="connectGoogle"
							icon="pi pi-google" iconPos="right" class="b-button p-button-outlined p-mr-2 p-mb-2"></Button>

				</div>

				<div  v-if="userData !== undefined && userData.has_google === true" class="card p-fluid">
					<h5>Choisir votre agenda</h5>
					<p class="text-muted">Choisissez l'agenda sur lequel seront sauvergardés les rendez-vous.</p>
					<CalendarSelector :value="userData.calendarId" @input="userData.calendarId = $event"></CalendarSelector>
					<Button label="Appliquer"
							@click="changeCalendar"
							icon="pi pi-save" class="b-button p-button-outlined p-mt-2 p-mb-2"></Button>
				</div>

				<div class="card p-fluid">
					<h5>Heures d'ouverture</h5>
					<p class="text-muted">Définissez ici vos heures d'ouverture pour la prise de rendez-vous.</p>
					<BusinesHoursEditor :readOnly="!userData.haveBusinessHours"></BusinesHoursEditor>
				</div>
			</div>


			<div class="card p-fluid">
				<h5>Re-initialiser le lien Google</h5>
				<p class="text-muted">Re-initialiser le lien avec votre compte google Google en cas de problème avec
					l'agenda et la prise de rendez-vous.</p>
				<p class="text-muted">Si le probléme persiste veuillez verifier que
					<a href="https://myaccount.google.com/permissions?continue=https%3A%2F%2Fmyaccount.google.com%2F%3Futm_source%3DOGB%26tab%3Dmk%26utm_medium%3Dact%26gar%3D1&gar=1"
					rel="noopener nofollow" target="_blank"
					>FreeCard n'est plus présent dans les applications de votre compte google</a></p>
				<div class="p-field">
					<Button label="Re-initialiser"
							@click="reinitGoogle"
							class="p-mt-2 p-mb-2 p-button-outlined p-button-danger"></Button>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import userMixin from "../mixins/userMixin";
	import ProfileService from "../service/ProfileService";
	import CalendarSelector from '../components/app/calendar/CalendarSelector.vue';
	import TimeHoursSelector from '../components/app/calendar/TimeHoursSelector.vue';
	import BusinesHoursEditor from '../components/app/calendar/BusinesHoursEditor.vue';

export default {
	data() {
		return {
			dut: undefined,
			loading: false,
			calendars: [],
			email: undefined,
			newPassword: '',
			newPasswordValidation: '',
		}
	},
	created(){
		this.profileService = new ProfileService();

	},
	mounted(){
		this.waitUser();
	},
	methods: {
		reinitGoogle(){
			this.profileService.reinitGoogle()
					.then(() => {
						this.$toast.add({severity:'success', summary: 'Succès', detail:'Informations mises à jour !', life: 3000});
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.loading = false;
						document.location.reload();
					})
			;
		},
		waitUser(){
			const vm = this;
			vm.loading = true;
			if(vm.userData === undefined){
				setTimeout(() => {
					vm.waitUser();
				}, 100);
				return;
			}
			vm.loading = false;
			vm.loadData();
		},
		loadData(){
			this.email = this.userData.username;
		},
		updateEmail(event){
			this.email = event.target.value;
		},
		changeProfilData(){
			let payload = {
				poste: this.userData.poste,
				lastname: this.userData.lastname,
				firstname: this.userData.firstname,
				email_notifications: Number(this.userData.email_notifications),
			};

			if(this.email !== this.userData.username){
				payload.username = this.email;
			}

			if(this.newPassword !== '' && this.newPassword === this.newPasswordValidation && this.passwordValid){
				payload.password = this.newPassword;
			}

			this.profileService.updateProfileInfo(payload)
					.then(data => {
						console.warn(data);
						this.$toast.add({severity:'success', summary: 'Succès', detail:'Informations mises à jour !', life: 3000});
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.loading = false;
					})
			;
		},
		changeCalendarSettings(){
			this.profileService.updateProfileInfo({
				subUserHaveBusinessHours: this.userData.subUserHaveBusinessHours,
				minimumHourBeforeMeeting: this.userData.minimumHourBeforeMeeting,
				prestationDurationMinute: this.userData.prestationDurationMinute,
				timeslotSizeMinute: this.userData.timeslotSizeMinute,
				notifyClientBeforeHours: this.userData.notifyClientBeforeHours,
				autoValidateMeetings: this.userData.autoValidateMeetings
			})
					.then(data => {
						console.warn(data);
						this.$toast.add({severity:'success', summary: 'Succès', detail:'Informations mises à jour !', life: 3000});
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.loading = false;
					})
			;
		},
		changeCalendar(){
			this.profileService.updateProfileInfo({
				calendarId: this.userData.calendarId,
			})
					.then(data => {
						console.warn(data);
						this.$toast.add({severity:'success', summary: 'Succès', detail:'Informations mises à jour !', life: 3000});
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.loading = false;
					})
			;
		},
		async connectGoogle(){
			this.loading = true;
			const authCode = await this.$gAuth.getAuthCode();
			//const response = await this.$http.post('http://your-backend-server-api-to-use-authcode', { code: authCode, redirect_uri: 'postmessage' })
			this.profileService.getGoogleTokens(authCode, '/')
					.then(data => {
						console.warn(data);
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.loading = false;
						this.$router.go();
					})

		}
	},
	computed: {
		...mapState({
			userData: 'user',
		}),
		passwordValid(){
			let passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

			if(this.newPassword === ''){
				return true;
			}

			return this.newPassword.match(passw);
		}
	},
	mixins: [userMixin],
	components:{
		CalendarSelector,
		TimeHoursSelector,
		BusinesHoursEditor,
	}
}
</script>

<style scoped>

</style>
