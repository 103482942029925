<template>
	<div class="selector input-field p-fluid">
		<div v-if="dropdownValues !== undefined" class="w-100 selector-inner">
			<Dropdown v-model="innerValue" @change="updateValue(e)"
                      :options="dropdownValues" optionLabel="name" placeholder="Sélectionnez une durée" />
		</div>
	</div>
</template>

<script>

export default {
	data() {
		return {
			minuteArray: [
				{
					name: "5 minutes",
					code: 5,
				},
				{
					name: "10 minutes",
					code: 10,
				},
				{
					name: "15 minutes",
					code: 15,
				},
				{
					name: "20 minutes",
					code: 20,
				},
				{
					name: "25 minutes",
					code: 25,
				},
				{
					name: "30 minutes",
					code: 30,
				},
				{
					name: "35 minutes",
					code: 35,
				},
				{
					name: "40 minutes",
					code: 40,
				},
				{
					name: "45 minutes",
					code: 45,
				},
				{
					name: "50 minutes",
					code: 50,
				},
				{
					name: "55 minutes",
					code: 55,
				},
				{
					name: "1 heure",
					code: 60,
				},
				{
					name: "1 heure 30",
					code: 90,
				},
				{
					name: "2 heure",
					code: 120,
				},
				{
					name: "2 heure 30",
					code: 150,
				},
				{
					name: "3 heure",
					code: 180,
				},
				{
					name: "3 heure 30",
					code: 210,
				},
			],
			linkTypesArray: [
				{
					name: "1 heure",
					code: 1,
				},
				{
					name: "2 heures",
					code: 2,
				},
				{
					name: "3 heures",
					code: 3,
				},
				{
					name: "4 heures",
					code: 4,
				},
				{
					name: "5 heures",
					code: 5,
				},
				{
					name: "6 heures",
					code: 6,
				},
				{
					name: "7 heures",
					code: 7,
				},
				{
					name: "8 heures",
					code: 8,
				},
				{
					name: "9 heures",
					code: 9,
				},
				{
					name: "10 heures",
					code: 10,
				},
				{
					name: "11 heures",
					code: 11,
				},
				{
					name: "12 heures",
					code: 12,
				},
				{
					name: "24 heures",
					code: 24,
				},
				{
					name: "2 jours",
					code: 24*2,
				},
				{
					name: "3 jours",
					code: 24*3,
				},
				{
					name: "4 jours",
					code: 24*4,
				},
				{
					name: "5 jours",
					code: 24*5,
				},
				{
					name: "6 jours",
					code: 24*6,
				},
				{
					name: "7 jours",
					code: 24*7,
				},

			],
			dropdownValues: undefined,
			innerValue: undefined,
		}
	},
	props: {
		value: {},
		minutesMode: {
			type: Boolean,
			default: false,
		},
		addNever: {
			type: Boolean,
			default: false,
		}
	},
	created() {

	},
	mounted() {
		const vm = this;
		this.innerValue = this.value;

		if(this.minutesMode){
			this.linkTypesArray = this.minuteArray;
		}


		if(this.addNever){
			this.linkTypesArray.unshift({
				name: "Jamais",
				code: -1,
			})
		}

		let arr = [];
		this.linkTypesArray.forEach(function(item){
			arr.push(item);
			if(item.code === vm.innerValue){
				vm.innerValue = item
			}
		});
		vm.dropdownValues = arr;
	},
	methods: {
		updateValue() {
			this.$emit('input', this.innerValue.code)
		}
	}
}
</script>

<style scoped>

</style>
