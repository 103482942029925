<template>
	<div class="selector input-field p-fluid">
		<div v-if="dropdownValues !== undefined" class="w-100 selector-inner">
			<Dropdown v-model="innerValue" @change="updateValue(e)"
                      :options="dropdownValues" optionLabel="name" placeholder="Sélectionnez une durée" />
		</div>
	</div>
</template>

<script>

export default {
	data() {
		return {
			linkTypesArray: [
				{
					name: "00:00",
				},
				{
					name: "00:15",
				},
				{
					name: "00:30",
				},
				{
					name: "00:45",
				},
				{
					name: "01:00",
				},
				{
					name: "01:00",
				},
				{
					name: "01:15",
				},
				{
					name: "01:30",
				},
				{
					name: "01:45",
				},

				{
					name: "02:00",
				},
				{
					name: "02:00",
				},
				{
					name: "02:15",
				},
				{
					name: "02:30",
				},
				{
					name: "02:45",
				},

				{
					name: "03:00",
				},
				{
					name: "03:00",
				},
				{
					name: "03:15",
				},
				{
					name: "03:30",
				},
				{
					name: "03:45",
				},

				{
					name: "04:00",
				},
				{
					name: "04:00",
				},
				{
					name: "04:15",
				},
				{
					name: "04:30",
				},
				{
					name: "04:45",
				},

				{
					name: "05:00",
				},
				{
					name: "05:00",
				},
				{
					name: "05:15",
				},
				{
					name: "05:30",
				},
				{
					name: "05:45",
				},

				{
					name: "06:00",
				},
				{
					name: "06:00",
				},
				{
					name: "06:15",
				},
				{
					name: "06:30",
				},
				{
					name: "06:45",
				},

				{
					name: "07:00",
				},
				{
					name: "07:00",
				},
				{
					name: "07:15",
				},
				{
					name: "07:30",
				},
				{
					name: "07:45",
				},

				{
					name: "08:00",
				},
				{
					name: "08:00",
				},
				{
					name: "08:15",
				},
				{
					name: "08:30",
				},
				{
					name: "08:45",
				},

				{
					name: "09:00",
				},
				{
					name: "09:00",
				},
				{
					name: "09:15",
				},
				{
					name: "09:30",
				},
				{
					name: "09:45",
				},

				{
					name: "10:00",
				},
				{
					name: "10:00",
				},
				{
					name: "10:15",
				},
				{
					name: "10:30",
				},
				{
					name: "10:45",
				},

				{
					name: "11:00",
				},
				{
					name: "11:00",
				},
				{
					name: "11:15",
				},
				{
					name: "11:30",
				},
				{
					name: "11:45",
				},

				{
					name: "12:00",
				},
				{
					name: "12:00",
				},
				{
					name: "12:15",
				},
				{
					name: "12:30",
				},
				{
					name: "12:45",
				},

				{
					name: "13:00",
				},
				{
					name: "13:00",
				},
				{
					name: "13:15",
				},
				{
					name: "13:30",
				},
				{
					name: "13:45",
				},

				{
					name: "14:00",
				},
				{
					name: "14:00",
				},
				{
					name: "14:15",
				},
				{
					name: "14:30",
				},
				{
					name: "14:45",
				},

				{
					name: "15:00",
				},
				{
					name: "15:00",
				},
				{
					name: "15:15",
				},
				{
					name: "15:30",
				},
				{
					name: "15:45",
				},

				{
					name: "16:00",
				},
				{
					name: "16:00",
				},
				{
					name: "16:15",
				},
				{
					name: "16:30",
				},
				{
					name: "16:45",
				},

				{
					name: "17:00",
				},
				{
					name: "17:00",
				},
				{
					name: "17:15",
				},
				{
					name: "17:30",
				},
				{
					name: "17:45",
				},

				{
					name: "18:00",
				},
				{
					name: "18:00",
				},
				{
					name: "18:15",
				},
				{
					name: "18:30",
				},
				{
					name: "18:45",
				},

				{
					name: "19:00",
				},
				{
					name: "19:00",
				},
				{
					name: "19:15",
				},
				{
					name: "19:30",
				},
				{
					name: "19:45",
				},

				{
					name: "20:00",
				},
				{
					name: "20:00",
				},
				{
					name: "20:15",
				},
				{
					name: "20:30",
				},
				{
					name: "20:45",
				},

				{
					name: "21:00",
				},
				{
					name: "21:00",
				},
				{
					name: "21:15",
				},
				{
					name: "21:30",
				},
				{
					name: "21:45",
				},

				{
					name: "22:00",
				},
				{
					name: "22:00",
				},
				{
					name: "22:15",
				},
				{
					name: "22:30",
				},
				{
					name: "22:45",
				},

				{
					name: "23:00",
				},
				{
					name: "23:00",
				},
				{
					name: "23:15",
				},
				{
					name: "23:30",
				},
				{
					name: "23:45",
				},
			],
			dropdownValues: undefined,
			innerValue: undefined,
		}
	},
	props: {
		value: {},
		addNever: {
			type: Boolean,
			default: false,
		}
	},
	created() {

	},
	mounted() {
		const vm = this;
		this.innerValue = this.value;

		if(this.addNever){
			this.linkTypesArray.unshift({
				name: "Jamais",
				code: -1,
			})
		}

		let arr = [];
		this.linkTypesArray.forEach(function(item){
			arr.push({
				name: item.name,
				code: item.name,
			});
			if(item.name === vm.innerValue){
				vm.innerValue = {
					name: item.name,
					code: item.name,
				}
			}
		});
		vm.dropdownValues = arr;
	},
	methods: {
		updateValue() {
			this.$emit('input', this.innerValue.code)
		}
	}
}
</script>

<style scoped>

</style>
