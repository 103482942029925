<template>
	<div style="width: 100%" class="selector input-field">
		<div v-if="dropdownValues !== undefined" class="w-100 selector-inner">
			<Dropdown v-model="innerValue" id="calendar_list_select" @change="updateValue(e)"
					   optionLabel="name"
                      :options="dropdownValues"  placeholder="Selectionnez un calendrier" />
		</div>
	</div>
</template>

<script>


import CalendarService from "../../../service/CalendarService";

export default {
	data() {
		return {
			dropdownValues: undefined,
			innerValue: undefined,
		}
	},
	props: {
		value: {}
	},
	created() {
		this.calendarService = new CalendarService();
	},
	mounted() {
		const vm = this;

		this.loading = true;
		this.calendarService.listCalendars()
				.then(res => {
					if(res !== undefined && res.data !== undefined){
						if(res.error === true){
							throw new Error(res.message);
						}

						let arr = [];

						res.data.data.forEach(function(item){
							arr.push({
								name: item.summary,
								code: item.id,
							});
							if(item.id === vm.value){
								vm.innerValue = {
									name: item.summary,
									code: item.id,
								}
							}
						});

						vm.dropdownValues = arr;
					}
				})
				.catch(err => {
					this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
				})
				.finally(() => {
					this.loading = false;
				})
	},
	methods: {
		updateValue() {
			console.log(this.innerValue);
			if(this.innerValue === undefined){
				return null;
			}
			this.$emit('input', this.innerValue.code)
		},
	},
	computed: {
	}
}
</script>

<style scoped>

</style>
