<template>
	<div class="p-fluid">
		<InfiniteProgress v-show="loading"></InfiniteProgress>
		<div v-if="hours !== undefined" class="p-grid p-fluid">
			<div v-for="hour in hours" :key="hour.id"  class="p-col-12">
				<div class="p-grid p-fluid">
					<div class="p-col-12">
								<span class="list-day p-mr-2 text-muted">
									{{ listOfDay(hour.daysOfWeek) }}
								</span>
						<span class="list-times p-mr-2">
									{{ hour.start }} - {{ hour.end }}
								</span>
						&nbsp;
						<span v-if="!readOnly">
							<a @click.prevent="hour.subMenu = false" v-if="hour.subMenu === true" href="#">Fermer</a>
							<a @click.prevent="hour.subMenu = true" v-else href="#">Modifier</a>
						</span>

					</div>
					<div v-show="hour.subMenu === true" class="p-col-12">
						<div class="card p-fluid">
							<div class="p-grid p-fluid">
								<div class="p-col-12 p-md-6">
									<div class="p-field p-d-flex p-jc-left p-ai-center">
										<InputSwitch :id="'mon_' + hour.id" v-model="hour.mon" />
										<div class="p-fluid p-ml-2">Lundi</div>
									</div>

									<div class="p-field p-d-flex p-jc-left p-ai-center">
										<InputSwitch :id="'tue_' + hour.id" v-model="hour.tue" />
										<div class="p-fluid p-ml-2">Mardi</div>
									</div>

									<div class="p-field p-d-flex p-jc-left p-ai-center">
										<InputSwitch :id="'wed_' + hour.id" v-model="hour.wed" />
										<div class="p-fluid p-ml-2">Mercredi</div>
									</div>

									<div class="p-field p-d-flex p-jc-left p-ai-center">
										<InputSwitch :id="'thu_' + hour.id" v-model="hour.thu" />
										<div class="p-fluid p-ml-2">Jeudi</div>
									</div>

									<div class="p-field p-d-flex p-jc-left p-ai-center">
										<InputSwitch :id="'fri_' + hour.id" v-model="hour.fri" />
										<div class="p-fluid p-ml-2">Vendredi</div>
									</div>

									<div class="p-field p-d-flex p-jc-left p-ai-center">
										<InputSwitch :id="'sat_' + hour.id" v-model="hour.sat" />
										<div class="p-fluid p-ml-2">Samedi</div>
									</div>

									<div class="p-field p-d-flex p-jc-left p-ai-center">
										<InputSwitch :id="'sun_' + hour.id" v-model="hour.sun" />
										<div class="p-fluid p-ml-2">Dimanche</div>
									</div>
								</div>
								<div class="p-col-12 p-md-6">
									<div class="p-field">
										<label>À PARTIR DE</label>
										<ClockSelector :value="hour.start" @input="hour.start = $event"></ClockSelector>
									</div>
									<div class="p-field">
										<label>À</label>
										<ClockSelector :value="hour.end" @input="hour.end = $event"></ClockSelector>
									</div>
								</div>
								<div class="p-col-12">
									<Button	@click="hour.dialog = true" class="p-button-danger p-button-outlined" label="supprimer" icon="pi pi-trash"></Button>
									<Dialog header="Confirmation" v-model:visible="hour.dialog" :style="{width: '350px'}" :modal="true">
										<div class="confirmation-content">
											<i class="pi pi-exclamation-triangle p-mr-3" style="display:block; font-size: 2rem" />
											<span>Êtes-vous sur de vouloir supprimer ces horaires ?</span>
										</div>
										<template #footer>
											<Button label="Non" icon="pi pi-times" @click="hour.dialog = false" class="p-button-text"/>
											<Button label="Oui, supprimer" icon="pi pi-check" @click="deleteBH(hour); hour.dialog = false"
													class="p-button-text" autofocus />
										</template>
									</Dialog>
								</div>
								<div v-if="!readOnly" class="p-col-12">
									<Button label="Mettre à jour"
											@click="updateBH(hour)"
											icon="pi pi-save" class="b-button p-mt-2 p-mb-2"></Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="!readOnly" class="p-col-12">
				<Button label="Ajouter les heures"
						@click="createBH"
						icon="pi pi-plus" class="p-button-secondary text-muted p-mt-2 p-mb-2"></Button>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import CalendarService from "../../../service/CalendarService";
	import ClockSelector from './ClockSelector.vue';
	import InfiniteProgress from '../../../components/InfiniteProgress.vue';

export default {
	data(){
		return {
			loading: false,
			hours: undefined,
		}
	},
	props: {
		readOnly: {
			type: Boolean,
			default: false,
		}
	},
	calendarService: undefined,
	created(){
		this.calendarService = new CalendarService();
	},
	mounted(){
		this.waitUser();
	},
	methods: {
		waitUser(){
			const vm = this;
			vm.loading = true;
			if(vm.userData === undefined){
				setTimeout(() => {
					vm.waitUser();
				}, 100);
				return;
			}
			vm.loading = false;
			this.loadData();
		},
		loadData(){
			this.calendarService.listBusinessHours()
					.then(res => {
						if(res !== undefined && res.data !== undefined){
							if(res.error === true){
								throw new Error(res.message);
							}
							this.hours = res.data.data;

						} else {
							throw new Error('Erreur inconnue');
						}

					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.loading = false;
					})
			;
		},
		listOfDay(arrayOfDay){
			let arr = [];

			if(arrayOfDay.includes(1)){
				arr.push('Lun');
			}
			if(arrayOfDay.includes(2)){
				arr.push('Mar');
			}
			if(arrayOfDay.includes(3)){
				arr.push('Mer');
			}
			if(arrayOfDay.includes(4)){
				arr.push('Jeu');
			}
			if(arrayOfDay.includes(5)){
				arr.push('Ven');
			}
			if(arrayOfDay.includes(6)){
				arr.push('Sam');
			}
			if(arrayOfDay.includes(0)){
				arr.push('Dim');
			}

			if(arr.length <= 0){
				arr.push('Aucun')
			}

			return arr.join(',');
		},
		updateBH(BhEntity){
			this.calendarService.updateBusinessHours(BhEntity['@id'],BhEntity)
					.then(res => {
						if(res.error === true){
							throw new Error(res.message);
						}
						this.loadData();
						this.$toast.add({severity:'success', summary: 'Succès', detail: 'Heures d\'ouverture mise à jour !', life: 3000});
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.loading = false;
					})
			;
		},
		createBH(){
			this.loading = true;
			this.calendarService.createBusinessHours()
					.then(res => {
						if(res.error === true){
							throw new Error(res.message);
						}
						this.loadData();
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.loading = false;
					})
			;
		},
		deleteBH(BHEntity){
			this.loading = true;
			this.calendarService.deleteBusinessHours(BHEntity['@id'])
					.then(res => {
						if(res.error === true){
							throw new Error(res.message);
						}
						this.$toast.add({severity:'success', summary: 'Succès', detail: 'Heures d\'ouverture supprimée !', life: 3000});
						this.loadData();
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.loading = false;
					})
			;
		}
	},
	computed:{
		...mapState({
			userData: 'user',
		}),
	},
	components: {
		InfiniteProgress,
		ClockSelector,
	}
}
</script>

<style scoped>

</style>
